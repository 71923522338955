(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("pslib-react-material_ui_themes-tembosocial-admin"));
	else if(typeof define === 'function' && define.amd)
		define(["pslib-react-material_ui_themes-tembosocial-admin"], factory);
	else if(typeof exports === 'object')
		exports["AchieversAdmin"] = factory(require("pslib-react-material_ui_themes-tembosocial-admin"));
	else
		root["PSLIB"] = root["PSLIB"] || {}, root["PSLIB"]["React"] = root["PSLIB"]["React"] || {}, root["PSLIB"]["React"]["Material_UI_Themes"] = root["PSLIB"]["React"]["Material_UI_Themes"] || {}, root["PSLIB"]["React"]["Material_UI_Themes"]["AchieversAdmin"] = factory(root["PSLIB"]["React"]["Material_UI_Themes"]["TemboSocialAdmin"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 