import tembo_theme from 'pslib-react-material_ui_themes-tembosocial-admin';

export default {
    ...tembo_theme,
    themeName: 'Achievers Admin Theme',
    overrides: {
        ...tembo_theme.overrides,
        MuiButton: {
            textPrimary: {
                color: '#4E008E',
            },
            raisedPrimary: {
                background: '#4E008E',
                backgroundColor: '#4E008E',
            },
            contained: {
                '&:focus': {
                    backgroundColor: '#4E008E',
                }
            },
            containedPrimary: {
                backgroundColor: '#4E008E',
                '&:hover': {
                    backgroundColor: '#753BBD'
                }
            }
        },
        MuiFab: {
            primary: {
                backgroundColor: '#4E008E',
                '&:hover': {
                    backgroundColor: '#753BBD',
                },
                '&:focus': {
                    backgroundColor: '#753BBD',
                }
            }
        },
        MuiSvgIcon: {
            colorPrimary: {
                color: '#4E008E'
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#4E008E'
            }
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: '#4E008E',
                '&:hover': {
                    backgroundColor: '#753BBD',
                },
            },
            current: {
                color: '#4E008E'
            }
        },
    },
    palette: {
        ...tembo_theme.palette,
        primary: {
            main: '#4E008E'
        }
    }
};
